import React, { useState, useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import FacebookCircularProgress from "../common/FacebookCircularProgress";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import uploadFile from "./handleUploadFile";
import AWS from "aws-sdk";
const UpdateFormContent = ({handleChange}) => {


  return (
    <div className="container mx-auto px-4 py-6">
      <form className="space-y-6">


        {/* FORM SECTION */}
      
        <div className="space-y-2">
          <div className="space-y-2">
            <label
              htmlFor="formImage"
              className="block text-sm font-medium text-gray-700"
            >
              Update Form Image
            </label>
            <input
              type="file"
              id="formImage"
              name="formImage"
              accept="image/*"
              onChange={handleChange}  // Use handleChange instead of handleFileUpload
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
            />
          </div>
        </div>
        {/* FORM SECTION END */}

     
     





      </form>
    </div>
  );
};

export default UpdateFormContent;
    