import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReviewsTable from "../../components/reviewsTable";
import axios from "axios";
import uploadFile from "../../components/handleUploadFile.js";
import FacebookCircularProgress from "../../common/FacebookCircularProgress";
import { toast, ToastContainer } from "react-toastify";

const ManageReviews = () => {
  const [review, setReview] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    review: "",
    stars: "",
    coverImage: null,
  });
  const [data, setData] = useState([]);

  const handleServices = () => {
    setReview(!review);
    setNewReview(!newReview);
    resetForm();
  };

  const [newReview, setNewReview] = useState(false);

  const handleAddReview = () => {
    setNewReview(!newReview);
    setReview(!review);
    resetForm();
  };

  const handleFileUpload = async (file) => {
    try {
      const fileUrl = await uploadFile(file); // Pass the file directly
      console.log("File successfully uploaded at:", fileUrl);
      setFormData((prevData) => ({
        ...prevData,
        coverImage: fileUrl,
      }));
    } catch (error) {
      console.error("File upload failed:", error.message);
    }
  };

  const handleChange = async (e) => {
    const { name, value, files } = e.target;

    if (name === "coverImage" && files.length > 0) {
      await handleFileUpload(files[0]); // Pass the first file from the files array
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const url = isEditing
      ? `${process.env.REACT_APP_BASE_URL}/review/updateReview/${formData._id}`
      : `${process.env.REACT_APP_BASE_URL}/review/createReview`;

    try {
      const method = isEditing ? "put" : "post";
      const response = await axios[method](url, formData);

      // const response = await axios({
      //   method: isEditing ? 'put' : 'post',
      //   url: endpoint,
      //   data: formData,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // });

      resetForm();
      setLoading(false);
      // console.log(response);
      // Replace with the actual library you're using
      const notify = () => toast(response.data.message);
      notify();
      await fetchReviews();

      setNewReview(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      const notify = () => toast("Something went wrong");
      notify();
    }
  };

  const fetchReviews = async () => {
    try {
      const getAllReviews = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/review/getAllReviews`
        // "http://localhost:8080/api/admin/review/getAllReviews"
      );
      setData(getAllReviews.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const deleteResponse = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/review/deleteReview/${id}`
        // `http://localhost:8080/api/admin/review/deleteReview/${id}`
      );
      const notify = () => toast(deleteResponse.data.message);
      notify();
      const updatedData = data.filter((item) => item._id !== id);
      setData(updatedData);
    } catch (error) {
      const notify = () => toast("Something Went Wrong");
      notify();
      console.error("Error:", error);
    }
  };

  const handleEdit = (item) => {
    setFormData(item);
    setIsEditing(true);
    setNewReview(true);
    setReview(false);
  };

  const resetForm = () => {
    setFormData({
      name: "",
      review: "",
      stars: "",
      coverImage: null,
    });
    setIsEditing(false);
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {newReview && (
        <div className="relative z-10">
          <div className="absolute top-[3rem] left-1/2 transform -translate-x-1/2 w-full max-w-[60rem]">
            <div className="bg-white p-6 rounded-lg shadow-lg mx-auto">
              <div className="flex justify-center items-center">
                <h2 className="text-xl font-bold mb-4">
                  {isEditing ? "Edit Review" : "Add Review"}
                </h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold text-sm">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Profile Image
                  </label>
                  <input
                    type="file"
                    name="coverImage"
                    accept="image/*"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold text-sm">
                    Stars
                  </label>
                  <input
                    type="text"
                    name="stars"
                    value={formData.stars}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold text-sm">
                    Review
                  </label>
                  <textarea
                    name="review"
                    onChange={handleChange}
                    value={formData.review}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
                    required
                  ></textarea>
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => {
                      setNewReview(false);
                      resetForm();
                    }}
                    className="bg-gray-500 h-10 px-3 justify-center text-center rounded-sm text-white text-sm font-semibold mx-5"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex bg-blue-500 h-10 px-3 justify-center items-center text-center rounded-sm text-white text-sm font-semibold mx-5"
                  >
                    {isEditing ? "Update Review" : "Add Review"}
                    {loading && <FacebookCircularProgress />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <div className="gap-5">
        {/* <button
          onClick={handleServices}
          className="h-10 px-3 justify-center text-center rounded-sm text-white text-sm font-semibold mx-5"
          style={{ background: review ? "#2F88EC" : "#589FEF" }}
        >
          All Reviews
        </button> */}
        <button
          onClick={handleAddReview}
          className="h-10 px-3 justify-center text-center rounded-sm text-white text-sm font-semibold mx-5"
          style={{ background: newReview ? "#2F88EC" : "#589FEF" }}
        >
          Add New Review
        </button>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Reviews
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <ReviewsTable
                  data={data}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default ManageReviews;
