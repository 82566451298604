import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useState, useEffect } from "react";
import Header from "layouts/profile/components/Header";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

function Overview() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    newpassword: "",
    confirmpassword: "",
  });

  const [errors, setErrors] = useState({
    nameError: false,
    emailError: false,
    newpasswordError: false,
    confirmpasswordError: false,
    passwordMismatch: false,
  });

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
    
      const response = await axios.get(  `${process.env.REACT_APP_BASE_URL}/getUserById`, config);
    
      // console.log("Response data:", response.data);

      setFormData({
        name: response.data.user.displayName || "",
        email: response.data.user.email || "",
        newpassword: "",
        confirmpassword: "",
      });

      localStorage.setItem("userName", response.data.user.displayName);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear specific field error
    setErrors({
      ...errors,
      [`${name}Error`]: false,
      passwordMismatch: false,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset errors
    const newErrors = {
      nameError: false,
      emailError: false,
      newpasswordError: false,
      confirmpasswordError: false,
      passwordMismatch: false,
    };

    // Validation
    if (formData.name.trim().length === 0) {
      newErrors.nameError = true;
    }
    if (formData.email.trim().length === 0) {
      newErrors.emailError = true;
    }
    if (formData.newpassword.trim().length === 0) {
      newErrors.newpasswordError = true;
    }
    if (formData.confirmpassword.trim().length === 0) {
      newErrors.confirmpasswordError = true;
    }
    if (formData.newpassword !== formData.confirmpassword) {
      newErrors.passwordMismatch = true;
    }

    if (Object.values(newErrors).includes(true)) {
      setErrors(newErrors);
      return;
    }

    // console.log("Form Data:", formData);

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token is missing or invalid");
      }

      // Construct the update request
     
      const response = await axios.put( `${process.env.REACT_APP_BASE_URL}/updateUser`, {
        displayName: formData.name,
        email: formData.email,
        newPassword: formData.newpassword,
        confirmPassword: formData.confirmpassword, // Changed to match backend
      }, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`, // Add token in headers for authorization
        },
      });
      fetchUserData(); // Refresh user data after update
     
      const notify = () => toast(response.data.message);
      notify();
    } catch (error) {
      
      const notify = () => toast("Something went wrong");
      notify();
      
      console.error("Error:", error);
    }
  };

  // Determine if the submit button should be disabled
  const isSubmitDisabled = Object.values(errors).includes(true);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <MDBox mt={6} sx={{ width: '100%' }}>
              <Card pb={2}>
                <Grid container spacing={3} p={2} mb={1}>
                  <Grid item xs={12}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={handleSubmit}
                      display="flex"
                      flexDirection="column"
                    >
                      <MDBox mb={3}>
                        <MDTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          Display Name
                        </MDTypography>
                        <MDInput
                          fullWidth
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          error={errors.nameError}
                        />
                        {errors.nameError && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                          >
                            Display Name cannot be empty
                          </MDTypography>
                        )}
                      </MDBox>
                      <MDBox mb={3}>
                        <MDTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          Email
                        </MDTypography>
                        <MDInput
                          fullWidth
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          error={errors.emailError}
                        />
                        {errors.emailError && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                          >
                            Email cannot be empty
                          </MDTypography>
                        )}
                      </MDBox>
                      <MDBox mb={3}>
                        <MDTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          New Password
                        </MDTypography>
                        <MDInput
                          fullWidth
                          name="newpassword"
                          type="password"
                          value={formData.newpassword}
                          onChange={handleChange}
                          error={errors.newpasswordError}
                        />
                        {errors.newpasswordError && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                          >
                            New Password cannot be empty
                          </MDTypography>
                        )}
                      </MDBox>
                      <MDBox mb={3}>
                        <MDTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          Confirm Password
                        </MDTypography>
                        <MDInput
                          fullWidth
                          name="confirmpassword"
                          type="password"
                          value={formData.confirmpassword}
                          onChange={handleChange}
                          error={errors.confirmpasswordError}
                        />
                        {errors.confirmpasswordError && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                          >
                            Confirm Password cannot be empty
                          </MDTypography>
                        )}
                        {errors.passwordMismatch && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                          >
                            Passwords do not match
                          </MDTypography>
                        )}
                      </MDBox>
                      <MDBox display="flex" justifyContent="start">
                        <MDButton
                          variant="gradient"
                          color="info"
                          type="submit"
                          disabled={isSubmitDisabled}
                          sx={{
                            bgcolor: isSubmitDisabled ? 'grey.400' : 'info.main',
                            '&:hover': {
                              bgcolor: isSubmitDisabled ? 'grey.500' : 'info.dark',
                            },
                          }}
                        >
                          Update Profile
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </Card>
            </MDBox>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
      <ToastContainer /> {/* Add ToastContainer for toast notifications */}
    </DashboardLayout>
  );
}

export default Overview;
