import AWS from "aws-sdk";

const S3_BUCKET = "welivegreen";
const REGION = "eu-north-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3({
  region: REGION,
});

const uploadFile = async (file) => {
  const params = {
    Bucket: S3_BUCKET,
    Key: file.name,
    Body: file,
    ContentType: file.type, // Set the correct MIME type
    ContentDisposition: "inline", // This should tell the browser to display the file
  };

  try {
    await s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${file.name}`;
    console.log("File URL:", url);
    return url;
  } catch (err) {
    console.error("Upload error: ", err);
    throw new Error("Error uploading file.");
  }
};

export default uploadFile;
