
import Dashboard from "layouts/dashboard";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Categories from "layouts/blogs/categories";
import Profile from "layouts/profile";
import NewPageComponent from "components/newPageComponent";
import NewPostComponent from "components/newPostComponent";
import NewHeading from "components/homePgaeText";
import Blogs from "layouts/blogs";
import MangeServices from "layouts/manageservices/manageServices";
import ManageReviews from "layouts/managereviews/manageReviews";
import Orders from "layouts/manageOrders/manageOrders";
import ManagePages from "layouts/managepages/managePages"
import ManageLeads from "layouts/manageleads/manageLeads.js"
import Login from "auth/login";
import Icon from "@mui/material/Icon";
import Questions from "./components/questions"

const routes = [
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Dashboard />,
  // },

  // {
  //   type: "title",
  //   name: "CMS",
  //   key: "cms",
  //   icon: <Icon fontSize="small">edit</Icon>,
  //   route: "/cms",
  //   children: [
  //     {
  //       type: "collapse",
  //       name: "Pages",
  //       key: "cms/pages",
  //       icon: <Icon fontSize="small">pages</Icon>,
  //       route: "/cms/pages",
  //       children: [
  //         {
  //           type: "collapse",
  //           name: "Add New Page",
  //           key: "cms/pages/add-new",
  //           route: "/cms/pages/add-new",
  //           component: <Billing />,
  //         },
  //         {
  //           type: "collapse",
  //           name: "Manage Pages",
  //           key: "services/manage-pages",
  //           route: "/services/manage-pages",
  //           component: <MangeServices />,
  //         },
  //       ],
  //     },

  //     {
  //       type: "collapse",
  //       name: "SEO",
  //       key: "cms/seo",
  //       icon: <Icon fontSize="small">search</Icon>,
  //       route: "/cms/seo",
  //       component: <Billing />,
  //     },
  //   ],
  // },

  {
    type: "title",
    name: "Services",
    key: "services",
    icon: <Icon fontSize="small">build</Icon>,
    route: "/services",
    children: [
      {
        type: "collapse",
        name: "Manage Services",
        key: "services/manage-services",
        route: "/services/manage-services",
        component: <MangeServices />,
      },
      // {
      //   type: "collapse",
      //   name: "SEO",
      //   key: "services/seo",
      //   route: "/services/seo",
      //   component: <Billing />,
      // },
    ],
  },
  {
    type: "title",
    name: "Blogs",
    key: "cms/blogs",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/cms/blogs",
    children: [
      {
        type: "collapse",
        name: "All Posts",
        key: "cms/blogs/posts",
        route: "/cms/blogs/posts",
        component: <Blogs />,
      },
      {
        type: "collapse",
        name: "New Post",
        key: "cms/blogs/new-post",
        route: "/cms/blogs/new-post",
        component: <NewPostComponent />,
      },
      {
        type: "collapse",
        name: "Categories",
        key: "cms/blogs/categories",
        route: "/cms/blogs/categories",
        component: <Categories />,
      },
    ],
  },
  {
    type: "title",
    name: "Pages",
    key: "pages",
    icon: <Icon fontSize="small">build</Icon>,
    route: "/pages",
    children: [
      {
        type: "collapse",
        name: "Pages List",
        key: "pages/manage-pages",
        route: "/pages/pageslist",
        component: <ManagePages />,
      },
      {
        type: "collapse",
        name: "New Page",
        key: "services/seo",
        route: "/pages/newpage",
        component: <NewPageComponent />,
      },
      {
        type: "collapse",
        name: "Update Content",
        key: "services/seo",
        route: "/pages/updatecontent",
        component: <NewHeading />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Reviews",
    key: "Reviews",
    icon: <Icon fontSize="small">build</Icon>,
    route: "/reviews",
    component: <ManageReviews />,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "Orders",
    icon: <Icon fontSize="small">build</Icon>,
    route: "/orders",
    component: <Orders />,
  },
  // {
  //   type: "collapse",
  //   name: "Leads",
  //   key: "Leads",
  //   icon: <Icon fontSize="small">build</Icon>,
  //   route: "/leads",
  //   component: <ManageLeads />,
  // },
  {
    type: "collapse",
    name: "Leads",
    key: "Leads",
    icon: <Icon fontSize="small">build</Icon>,
    route: "/leads",
    component: <Questions />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "examples",
  //   name: "User Profile",
  //   key: "user-profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/user-profile",
  //   component: <UserProfile />,
  // },
  // {
  //   type: "examples",
  //   name: "User Management",
  //   key: "user-management",
  //   icon: <Icon fontSize="small">list</Icon>,
  //   route: "/user-management",
  //   component: <UserManagement />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
  {
    type: "auth",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <Login />,
  },
  // {
  //   type: "auth",
  //   name: "Login",
  //   key: "login",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/auth/login",
  //   component: <Signup />,
  // },
  // {
  //   type: "auth",
  //   name: "Register",
  //   key: "register",
  //   icon: <Icon fontSize="small">register</Icon>,
  //   route: "/auth/register",
  //   component: <Register />,
  // },
  // {
  //   type: "auth",
  //   name: "Forgot Password",
  //   key: "forgot-password",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/auth/forgot-password",
  //   component: <ForgotPassword />,
  // },
  // {
  //   type: "auth",
  //   name: "Reset Password",
  //   key: "reset-password",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/auth/reset-password",
  //   component: <ResetPassword />,
  // },
];

export default routes;
