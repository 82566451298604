import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AddNewPost from "./addNewPost";
import axios from "axios";
import { useEffect,useState } from "react";
const NewPostComponent = () => {



  const idTOEditBlog = localStorage.getItem("idTOEditBlog");
  // console.log(idTOEditBlog);

const [data, setData] = useState({});

  useEffect(() => {
    if (idTOEditBlog) {
      const fetchData = async () => {
        try {
          const response = await axios.get( `${process.env.REACT_APP_BASE_URL}/blog/getBlogById/${idTOEditBlog}`);
          // const response = await axios.get(`http://localhost:8080/api/admin/blog/getBlogById/${idTOEditBlog}`);
          // console.log("Response data:", response.data);
          setData(response.data);
        } catch (error) {
          console.error("Error:", error);
        }
      };
      fetchData();
    }
    
  }, []);


  const [categories,setCategories] =useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
       
        // const response = await axios.get("http://localhost:8080/api/admin/category/getAllCategories");
        const response = await axios.get( `${process.env.REACT_APP_BASE_URL}/category/getAllCategories`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  New Blog Post
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <AddNewPost categories={categories} data={data}/>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewPostComponent;
