import React, { useState, useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import FacebookCircularProgress from "../common/FacebookCircularProgress";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import uploadFile from "./handleUploadFile";
import AWS from "aws-sdk";
const AddNewPost = ({ categories, data }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    category: "",
    description: "",
    content: "",
    url: "",
    status: "draft",
    enableComment: "disabled",
    coverImage: null, // Change to null for file handling
    seoMetaDescription: "",
  });
  const [file, setFile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      // console.log(data);
      setFormData(data);
      setIsEditing(true);
    }
  }, [data]);

  const [loading, setLoading] = useState(false);
  const handleContentChange = (content) => {
    setFormData((prevData) => ({
      ...prevData,
      content,
    }));
  };

  const resetForm = () => {
    setFormData({
      title: "",
      category: "",
      description: "",
      content: "",
      url: "",
      status: "draft",
      enableComment: "disabled",
      coverImage: null,
      seoMetaDescription: "",
    });
  };

  const handleDescriptionChange = (content) => {
    setFormData((prevData) => ({
      ...prevData,
      description: content,
    }));
  };

  const handleFileUpload = async (e) => {
    const selectedFile = e.target.files[0];
    try {
      if (selectedFile) {
        const fileUrl = await uploadFile(selectedFile);
        console.log("File successfully uploaded at:", fileUrl);

        // Update formData with the uploaded file's URL
        setFormData((prevData) => ({
          ...prevData,
          coverImage: fileUrl,
        }));
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };

  const handleChange = async (e) => {
    const { name, value, files } = e.target;

    if (name === "coverImage") {
      // Upload file and set the coverImage value in formData
      await handleFileUpload(e);
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  // const handleFileChange = async (e) => {
  //   const selectedFile = e.target.files[0];

  //   if (selectedFile) {
  //     await uploadFile(selectedFile);
  //   }
  // };

  // const handleFileUpload = async (e) => {
  //   const selectedFile = e.target.files[0];
  //   try {
  //     if (selectedFile) {
  //       // await uploadFile(selectedFile);
  //       const fileUrl = await uploadFile(selectedFile);
  //       setFile(fileUrl);
  //       console.log("File successfully uploaded at:", fileUrl);
  //     }
  //   } catch (error) {
  //     console.error("File upload failed:", error);
  //   }
  // };

  // const [imageUrl, setImageUrl] = useState(null);
  // const uploadFile = async (file) => {
  //   const S3_BUCKET = "welivegreen";
  //   const REGION = "eu-north-1";

  //   AWS.config.update({
  //     accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  //     secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  //   });

  //   const s3 = new AWS.S3({
  //     region: REGION,
  //   });

  //   const params = {
  //     Bucket: S3_BUCKET,
  //     Key: file.name,
  //     Body: file,
  //     ContentType: file.type, // Set the correct MIME type
  //     ContentDisposition: "inline", // This should tell the browser to display the file
  //   };

  //   try {
  //     await s3
  //       .putObject(params)
  //       .on("httpUploadProgress", (evt) => {
  //         console.log(
  //           "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
  //         );
  //       })
  //       .promise();

  //     const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${file.name}`;
  //     setImageUrl(url);
  //     console.log("File URL:", url);
  //     alert("File uploaded successfully.");
  //     return url;
  //   } catch (err) {
  //     console.error("Upload error: ", err);
  //     alert("Error uploading file.");
  //   }
  // };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    // const submissionData = new FormData();
    // for (const key in formData) {
    //   submissionData.append(key, formData[key]);
    // }

    try {
      let response;
      if (isEditing) {
        // console.log(data._id);
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            authorization: `Bearer ${token}`,
          },
        };

        response = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/blog/updateBlog/${data._id}`,
          // `http://localhost:8080/api/admin/blog/updateBlog/${data._id}`,
          formData,
          config,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setIsEditing(false);
      } else {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/blog/createBlog`,
          // "http://localhost:8080/api/admin/blog/createBlog",
          formData,
          config,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

      setTimeout(() => {
        resetForm();
      }, 200);

      setLoading(false);
      // console.log(response.data.message);

      const notify = () => toast(response.data.message);
      notify();
      navigate(`/cms/blogs/posts`); // Adjust the path as necessary
    } catch (error) {
      console.error("Error:", error);
      const notify = () => toast("Something went wrong");
      notify();
      setLoading(false);
    }
  };

  const editorRef = useRef(null);

  // Cleanup effect to clear local storage and reset form data on unmount
  useEffect(() => {
    return () => {
      localStorage.removeItem("idTOEditBlog");
      resetForm();
    };
  }, []);

  return (
    <div className="container mx-auto px-4 py-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
            />
          </div>

          <div className="space-y-2">
            <label
              htmlFor="category"
              className="block text-sm font-medium text-gray-700"
            >
              Category
            </label>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
            >
              <option value="">Select a category</option>
              {categories?.map((category) => (
                <option key={category?.category} value={category?.category}>
                  {category?.category}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <label
              htmlFor="coverImage"
              className="block text-sm font-medium text-gray-700"
            >
              Cover Image
            </label>
            <input
              type="file"
              id="coverImage"
              name="coverImage"
              accept="image/*"
              onChange={handleFileUpload}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
            />
            {/* {formData.coverImage && (
              <img
                className="w-full h-64 mt-2"
                src={URL.createObjectURL(formData.coverImage)}
                alt="Cover Image"
              />
            )} */}
          </div>
          <div className="space-y-2">
            <label
              htmlFor="url"
              className="block text-sm font-medium text-gray-700"
            >
              URL
            </label>
            <input
              type="text"
              id="url"
              name="url"
              value={formData.url}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
            />
          </div>
        </div>

        <div className="space-y-2">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <Editor
            apiKey="9s5ou8sknrgi85liuccpawfylv0y58eudqkdj43cci3yqrj0"
            onInit={(evt, editor) => (editorRef.current = editor)}
            name="description"
            value={formData.description}
            onEditorChange={handleDescriptionChange}
            init={{
              height: "40vh",
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              outputFormat: "html",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </div>

        <div className="space-y-2">
          <label
            htmlFor="content"
            className="block text-sm font-medium text-gray-700"
          >
            Content
          </label>
          <textarea
            name="content"
            id="content"
            value={formData.content}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          ></textarea>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Status
          </label>
          <div className="flex items-center space-x-4">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="status"
                checked={formData.status === "published"}
                onChange={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    status: prevData.status === "draft" ? "published" : "draft",
                  }))
                }
                className="sr-only"
              />
              <div className="relative">
                <div
                  className={`w-10 h-4 bg-[#3790EE] rounded-full shadow-inner ${
                    formData.status === "published"
                      ? "bg-[#3790EE]"
                      : "bg-[#3790EE]"
                  }`}
                ></div>
                <div
                  className={`dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition ${
                    formData.status === "published"
                      ? "transform translate-x-full"
                      : ""
                  }`}
                ></div>
              </div>
              <span className="ml-3 text-sm">
                {formData.status === "published" ? "published" : "draft"}
              </span>
            </label>
          </div>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Comment
          </label>
          <div className="flex items-center space-x-4">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="enableComment"
                checked={formData.enableComment === "enabled"}
                onChange={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    enableComment:
                      prevData.enableComment === "enabled"
                        ? "disabled"
                        : "enabled",
                  }))
                }
                className="sr-only"
              />
              <div className="relative">
                <div
                  className={`w-10 h-4 bg-[#3790EE] rounded-full shadow-inner ${
                    formData.enableComment === "enabled"
                      ? "bg-[#3790EE]"
                      : "bg-[#3790EE]"
                  }`}
                ></div>
                <div
                  className={`dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition ${
                    formData.enableComment === "enabled"
                      ? "transform translate-x-full"
                      : ""
                  }`}
                ></div>
              </div>
              <span className="ml-3 text-sm">
                {formData.enableComment === "enabled" ? "enabled" : "disabled"}
              </span>
            </label>
          </div>
        </div>

        <div className="space-y-2">
          <label
            htmlFor="seoMetaDescription"
            className="block text-sm font-medium text-gray-700"
          >
            SEO Meta Description
          </label>
          <textarea
            id="seoMetaDescription"
            name="seoMetaDescription"
            value={formData.seoMetaDescription}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          ></textarea>
        </div>

        <div className="pt-5">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#3790EE] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3790EE]"
          >
            {isEditing ? "Update" : "Save"}

            {loading && (
              <>
                <FacebookCircularProgress />
              </>
            )}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddNewPost;
