import React, { useState, useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import FacebookCircularProgress from "../common/FacebookCircularProgress";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import uploadFile from "./handleUploadFile";
import AWS from "aws-sdk";
const UpdateContent = ({ formData, handleChange }) => {

  return (
    <div className="container mx-auto px-4 py-6">
      <form  className="space-y-6">

        <div className="space-y-2">
          <div className="space-y-2">
            <label className="block text-gray-700 font-bold text-sm">
              H1 Tag
            </label>
            <input
              type="text"
              name="heading"
              value={formData.heading}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
              onChange={handleChange}
              required
            />
          </div>

          <div className="space-y-2">
            <label className="block text-gray-700 font-bold text-sm">
              Header Content
            </label>

            <textarea
              name="subheading"
              value={formData.subheading}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default UpdateContent;
