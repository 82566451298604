import React, { useState, useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import FacebookCircularProgress from "../common/FacebookCircularProgress";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import uploadFile from "./handleUploadFile";
import AWS from "aws-sdk";
const UpdateReviewsSection = ({formData,handleChange  }) => {
 
  return (
    <div className="container mx-auto px-4 py-6">
      <form className="space-y-6">

        {/* REVIEWS SECTION */}

        <div className="space-y-2">
          <label
            htmlFor="url"
            className="block text-sm font-medium text-gray-700"
          >
            Review Title
          </label>
          <input
            type="text"
            name="reviewsTitle"
            id="reviewsTitle"
            value={formData.reviewsTitle}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          />
        </div>
        <div className="space-y-2">
          <label
            htmlFor="url"
            className="block text-sm font-medium text-gray-700"
          >
            Reviews Content
          </label>
          <textarea
            name="reviewsContent"
            id="reviewsContent"
            value={formData.reviewsContent}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          ></textarea>
        </div>

        {/* SERVICE SECTION END */}

      </form>
    </div>
  );
};

export default UpdateReviewsSection;
    