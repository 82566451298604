import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Editor } from "@tinymce/tinymce-react";
import FacebookCircularProgress from "../common/FacebookCircularProgress";
import axios from "axios";
import uploadFile from "./handleUploadFile.js";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddNewPage = () => {
  const navigate = useNavigate();
  const initialFormData = useMemo(
    () => ({
      name: "",
      url: "",
      title: "",
      metaDescription: "",
      content: "",
      status: "Draft",
      seoRobotAccess: "Follow",
      coverImage: null,
    }),
    []
  );
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const idTOEditPage = localStorage.getItem("idTOEditPage");
    if (idTOEditPage) {
      setIsEditing(true);
      (async () => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/page/getPageById/${idTOEditPage}`
          );
          setFormData(data);
          localStorage.removeItem("idTOEditPage");
        } catch (error) {
          console.error("Error:", error);
        }
      })();
    }
  }, []);

  const handleFileUpload = useCallback(async (file) => {
    try {
      const fileUrl = await uploadFile(file);
      setFormData((prevData) => ({ ...prevData, coverImage: fileUrl }));
    } catch (error) {
      console.error("File upload failed:", error.message);
    }
  }, []);

  const handleChange = useCallback(
    async (e) => {
      const { name, value, files } = e.target;
      if (name === "coverImage" && files.length > 0) {
        await handleFileUpload(files[0]);
      } else {
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      }
    },
    [handleFileUpload]
  );

  const handleContentChange = useCallback((content) => {
    setFormData((prevData) => ({ ...prevData, content }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        };

        const response = isEditing
          ? await axios.put(
              `${process.env.REACT_APP_BASE_URL}/page/updatePage/${formData._id}`,
              formData,
              config
            )
          : await axios.post(
              `${process.env.REACT_APP_BASE_URL}/page/createPage`,
              formData,
              config
            );

        // if (isEditing) {
        //   toast("Page Updated Successfully");
        // } else {
        //   toast("Page Created Successfully");
        // }

        setTimeout(() => resetForm(), 200);

        // Delay navigation to allow toast to show
        setTimeout(() => {
          navigate(`/pages/pageslist`);
        }, 1000); // 1 second delay
      } catch (error) {
        console.error("Error:", error);
        toast("Something went wrong. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [formData, isEditing, navigate]
  );

  const resetForm = useCallback(() => {
    setFormData(initialFormData);
    setIsEditing(false);
  }, [initialFormData]);

  const editorRef = useRef(null);
  return (
    <div className="container mx-auto px-4 py-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
          <div className="space-y-2">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
            />
          </div>

          <div className="space-y-2">
            <label
              htmlFor="url"
              className="block text-sm font-medium text-gray-700"
            >
              URL
            </label>
            <input
              type="text"
              id="url"
              name="url"
              value={formData.url}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
            />
          </div>
        </div>

        <div className="space-y-2 mb-4">
          <label
            htmlFor="coverImage"
            className="block text-sm font-medium text-gray-700"
          >
            Cover Image
          </label>
          <input
            type="file"
            id="coverImage"
            name="coverImage"
            accept="image/*"
            onChange={(e) => handleChange(e)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          />
        </div>

        <div className="space-y-2">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:#3790EE focus:border-[#3790EE] sm:text-sm"
          />
        </div>

        <div className="space-y-2">
          <label
            htmlFor="metaDescription"
            className="block text-sm font-medium text-gray-700"
          >
            SEO Meta Description
          </label>
          <textarea
            id="metaDescription"
            name="metaDescription"
            value={formData.metaDescription}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:#3790EE focus:border-[#3790EE] sm:text-sm"
          ></textarea>
        </div>

        <div className="space-y-2">
          <label
            htmlFor="content"
            className="block text-sm font-medium text-gray-700"
          >
            Content
          </label>
          <Editor
            apiKey="9s5ou8sknrgi85liuccpawfylv0y58eudqkdj43cci3yqrj0"
            onInit={(evt, editor) => (editorRef.current = editor)}
            name="content"
            value={formData.content}
            initialValue="<p>This is the initial content of the editor.</p>"
            onEditorChange={handleContentChange}
            init={{
              top: "15vh",
              height: "40vh",
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              outputFormat: "html",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Status
          </label>
          <div className="flex items-center space-x-4">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="status"
                checked={formData.status === "Published"}
                onChange={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    status: prevData.status === "Draft" ? "Published" : "Draft",
                  }))
                }
                className="sr-only"
              />
              <div className="relative">
                <div
                  className={`w-10 h-4 bg-gray-200 rounded-full shadow-inner ${
                    formData.status === "Published"
                      ? "bg-[#3790EE]"
                      : "bg-[#3790EE]"
                  }`}
                ></div>
                <div
                  className={`dot absolute w-6 h-6 bg-[white] rounded-full shadow -left-1 -top-1 transition ${
                    formData.status === "Published"
                      ? "transform translate-x-full"
                      : ""
                  }`}
                ></div>
              </div>
              <span className="ml-3 text-sm">
                {formData.status === "Published" ? "Published" : "Draft"}
              </span>
            </label>
          </div>
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            SEO Robot Access
          </label>
          <div className="flex items-center space-x-4">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="seoRobotAccess"
                checked={formData.seoRobotAccess === "Follow"}
                onChange={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    seoRobotAccess:
                      prevData.seoRobotAccess === "Follow"
                        ? "No follow"
                        : "Follow",
                  }))
                }
                className="sr-only"
              />

              <div className="relative">
                <div
                  className={`w-10 h-4 bg-gray-200 rounded-full shadow-inner ${
                    formData.seoRobotAccess === "Follow"
                      ? "bg-[#3790EE]"
                      : "bg-[#3790EE]"
                  }`}
                ></div>

                <div
                  className={`dot absolute w-6 h-6 bg-[white] rounded-full shadow -left-1 -top-1 transition ${
                    formData.seoRobotAccess === "Follow"
                      ? "transform translate-x-full"
                      : ""
                  }`}
                ></div>
              </div>
              <span className="ml-3 text-sm">
                {formData.seoRobotAccess === "Follow" ? "Follow" : "No follow"}
              </span>
            </label>
          </div>
        </div>

        <div className="pt-5">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#3790EE] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:#3790EE"
          >
            Save
            {loading && (
              <>
                <FacebookCircularProgress />
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewPage;
