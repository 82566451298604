import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import UpdateContent from "../components/updateContent";
import UpdateFormContent from "../components/updateFormContent";
import UpdateAboutUs from "../components/updateAboutUs";
import UpdateReviewsSection from "../components/updateReviewes";
import UpdateServicesContent from "../components/updateServices";
import UpdateBlogsSection from "../components/updateBlogs";
import UpdateContactUs from "../components/updateContactUs";
import UpdateNavItems from "../components/updateNavItems";
import axios from "axios";
import uploadFile from "./handleUploadFile";
import FacebookCircularProgress from "../common/FacebookCircularProgress";
import { toast, ToastContainer } from "react-toastify";

const HomePageText = () => {
  const [loading, setLoading] = useState(false);
  const [idToEdit, setIdToEdit] = useState(null);

  // console.log(idToEdit);
  const [formData, setFormData] = useState({
    heading: "",
    subheading: "",
    aboutUsTitle: "",
    aboutUsContent: "",
    servicesHeading: "",
    servicesContent: "",
    reviewsTitle: "",
    reviewsContent: "",
    blogsTitle: "",
    blogsContent: "",
    contactUsTitle: "",
    contactUsContent: "",
    formImage: null,
    aboutUsImage: null,
    navItemOne: "",
    navItemTwo: "",
    navItemThree: "",
    navItemFour: "",
    navItemButtonText: "",
  });

  // useEffect(() => {
  //   console.log(formData);
  // },[formData])

  const resetForm = () => {
    setFormData({
      heading: "",
      subheading: "",
      aboutUsTitle: "",
      aboutUsContent: "",
      servicesHeading: "",
      servicesContent: "",
      reviewsTitle: "",
      reviewsContent: "",
      blogsTitle: "",
      blogsContent: "",
      contactUsTitle: "",
      contactUsContent: "",
      formImage: null,
      aboutUsImage: null,
      navItemOne: "",
      navitemTwo: "",
      navItemThree: "",
      navItemFour: "",
      navItemButtonText: "",
    });
  };

  const handleChange = async (e) => {
    const { name, value, files } = e.target;

    if (
      name === "coverImage" ||
      name === "formImage" ||
      name === "aboutUsImage"
    ) {
      // Upload file and set the corresponding value in formData
      await handleFileUpload(name, e);
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleFileUpload = async (fieldName, e) => {
    const selectedFile = e.target.files[0];
    try {
      if (selectedFile) {
        const fileUrl = await uploadFile(selectedFile);
        console.log(`File successfully uploaded at: ${fileUrl}`);

        // Update formData with the uploaded file's URL
        setFormData((prevData) => ({
          ...prevData,
          [fieldName]: fileUrl,
        }));
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const url = `${process.env.REACT_APP_BASE_URL}/updateContent/updateData/${idToEdit}`;
      const response = await axios.put(url, formData);

      // Display success notification
      toast.success(response?.data?.message || "Blog updated successfully!");
    } catch (error) {
      console.error("Error updating blog:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/updateContent/getData`
        );
        // console.log(response.data.content[0]);
        setIdToEdit(response.data.content[0]._id);
        setFormData(response.data.content[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchInitialData();
  }, []); // Empty dependency array to ensure the data fetch only runs once, on

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* UPDATE HOME CONTENT */}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Update Content
                </MDTypography>
              </MDBox>
              <MDBox pt={3} className="overflow-hidden">
                <UpdateContent
                  formData={formData}
                  handleChange={handleChange}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* UPDATE FORM CONTENT */}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Update Form Content
                </MDTypography>
              </MDBox>
              <MDBox pt={3} className="overflow-hidden">
                <UpdateFormContent handleChange={handleChange} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* UPDATE ABOUT US */}

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Update About Us Content
                </MDTypography>
              </MDBox>
              <MDBox pt={3} className="overflow-hidden">
                <UpdateAboutUs
                  formData={formData}
                  handleChange={handleChange}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/*   UPDATE SERVICES CONTENT */}

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Update Services Content
                </MDTypography>
              </MDBox>
              <MDBox pt={3} className="overflow-hidden">
                <UpdateServicesContent
                  formData={formData}
                  handleChange={handleChange}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* UPDATE REVIEWS SECTION */}

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Update Reviews Content
                </MDTypography>
              </MDBox>
              <MDBox pt={3} className="overflow-hidden">
                {/* <ReviewsTable
                  data={data}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                /> */}
                {/* <HeadingsTable
                  data={data}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                /> */}

                <UpdateReviewsSection
                  formData={formData}
                  handleChange={handleChange}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* UPDATE BLOGS SECTION */}

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Update Blogs Content
                </MDTypography>
              </MDBox>
              <MDBox pt={3} className="overflow-hidden">
                <UpdateBlogsSection
                  formData={formData}
                  handleChange={handleChange}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* UPDATE CONTACT US SECTION */}

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Update Contact Us Content
                </MDTypography>
              </MDBox>
              <MDBox pt={3} className="overflow-hidden">
                <UpdateContactUs
                  formData={formData}
                  handleChange={handleChange}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Update Navbar Content
                </MDTypography>
              </MDBox>
              <MDBox pt={3} className="overflow-hidden">
                <UpdateNavItems
                  formData={formData}
                  handleChange={handleChange}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <div className="pt-5">
        <button
          type="submit"
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#3790EE] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3790EE]"
          onClick={handleSubmit}
        >
          Update
          {loading && (
            <>
              <FacebookCircularProgress />
            </>
          )}
        </button>
      </div>

      <Footer />
    </DashboardLayout>
  );
};

export default HomePageText;
