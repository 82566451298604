import React, { useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import FacebookCircularProgress from "../common/FacebookCircularProgress";

const EditBlogs = ({ categories, data }) => {
  const [formData, setFormData] = useState({
    title: "",
    category: "",
    description: "",
    content: "",
    url: "",
    status: "draft",
    enableComment: "disabled",
    coverImage: "",
  });
  const [loading, setLoading] = useState(false);
  const handleContentChange = (content) => {
    setFormData((prevData) => ({
      ...prevData,
      content: content,
    }));
  };

  const resetForm = () => {
    setFormData({
      title: "",
      category: "",
      description: "",
      content: "",
      url: "",
      status: "draft",
      enableComment: "disabled",
      coverImage: "",
    });
  };
  const handleDescriptionChange = (content) => {
    setFormData((prevData) => ({
      ...prevData,
      description: content,
    }));
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "coverImage") {
      setFormData((prevData) => ({
        ...prevData,
        // coverImage: files[0],
        coverImage:
          "https://shotkit.com/wp-content/uploads/bb-plugin/cache/cool-profile-pic-matheus-ferrero-landscape-6cbeea07ce870fc53bedd94909941a4b-zybravgx2q47.jpeg",
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const submissionData = new FormData();
    for (const key in formData) {
      submissionData.append(key, formData[key]);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/blog/createBlog`,
        // "http://localhost:8080/api/admin/blog/createBlog",
        formData,
        {
          // headers: {
          //   "Content-Type": "multipart/form-data",
          // },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setTimeout(() => {
        resetForm();
      }, 200);
      setLoading(false);
      // console.log(response.data);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }

    // console.log("Form submitted:", formData);
  };

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
    }
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
            />
          </div>

          <div className="space-y-2">
            <label
              htmlFor="category"
              className="block text-sm font-medium text-gray-700"
            >
              Category
            </label>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
            >
              <option value={""}>Select a category</option>
              {categories?.map((category) => {
                return (
                  <>
                    <option value={category?.category}>
                      {category?.category}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <label
              htmlFor="coverImage"
              className="block text-sm font-medium text-gray-700"
            >
              Cover Image
            </label>
            <input
              type="file"
              id="coverImage"
              name="coverImage"
              accept="image/*"
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
            />
          </div>
          <div className="space-y-2">
            <label
              htmlFor="url"
              className="block text-sm font-medium text-gray-700"
            >
              URL
            </label>
            <input
              type="text"
              id="url"
              name="url"
              value={formData.url}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
            />
          </div>
        </div>

        {/* {formData.coverImage && (
          <img
            className="w-full h-64"
            src={URL.createObjectURL(formData.coverImage)}
            alt="Cover Image"
          />
        )} */}

        <div className="space-y-2">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <Editor
            apiKey="9s5ou8sknrgi85liuccpawfylv0y58eudqkdj43cci3yqrj0"
            onInit={(evt, editor) => (editorRef.current = editor)}
            name="description"
            value={formData.description}
            initialValue="<p>This is the initial content of the editor.</p>"
            onEditorChange={handleDescriptionChange}
            init={{
              top: "15vh",
              height: "40vh",
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              outputFormat: "html",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </div>

        <div className="space-y-2">
          <label
            htmlFor="content"
            className="block text-sm font-medium text-gray-700"
          >
            Content
          </label>
          {/* <Editor
            apiKey="7dbfi1qg9fuq139dxyc97pae82a1o85lbz0p2ntbjcjoq155"
            onInit={(evt, editor) => (editorRef.current = editor)}
            name="content"
            value={formData.content}
            initialValue="<p>This is the initial content of the editor.</p>"
            onEditorChange={handleContentChange}
            init={{
              top: "15vh",
              height: "40vh",
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              outputFormat: "html",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          /> */}
          <textarea
            name="content"
            id="content"
            value={formData.content}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          ></textarea>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Status
          </label>
          <div className="flex items-center space-x-4">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="status"
                checked={formData.status === "published"}
                onChange={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    status: prevData.status === "draft" ? "published" : "draft",
                  }))
                }
                className="sr-only"
              />
              <div className="relative">
                <div
                  className={`w-10 h-4 bg-[#3790EE] rounded-full shadow-inner ${
                    formData.status === "published"
                      ? "bg-[#3790EE]"
                      : "bg-[#3790EE]"
                  }`}
                ></div>
                <div
                  className={`dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition ${
                    formData.status === "published"
                      ? "transform translate-x-full"
                      : ""
                  }`}
                ></div>
              </div>
              <span className="ml-3 text-sm">
                {formData.status === "published" ? "published" : "draft"}
              </span>
            </label>
          </div>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Comment
          </label>
          <div className="flex items-center space-x-4">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="enableComment"
                checked={formData.enableComment === "enabled"}
                onChange={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    enableComment:
                      prevData.enableComment === "enabled"
                        ? "disabled"
                        : "enabled",
                  }))
                }
                className="sr-only"
              />
              <div className="relative">
                <div
                  className={`w-10 bg-[#3790EE] h-4 rounded-full shadow-inner ${
                    formData.enableComment === "enabled"
                      ? "bg-[#3790EE]"
                      : "bg-[#3790EE]"
                  }`}
                ></div>
                <div
                  className={`dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition ${
                    formData.enableComment === "enabled"
                      ? "transform translate-x-full"
                      : ""
                  }`}
                ></div>
              </div>
              <span className="ml-3 text-sm">
                {formData.enableComment === "enabled" ? "enabled" : "disabled"}
              </span>
            </label>
          </div>
        </div>

        <div className="space-y-2">
          <label
            htmlFor="seoMetaDescription"
            className="block text-sm font-medium text-gray-700"
          >
            SEO Meta Description
          </label>
          <textarea
            id="seoMetaDescription"
            name="seoMetaDescription"
            value={formData.seoMetaDescription}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          ></textarea>
        </div>

        <div className="pt-5">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#3790EE] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:#3790EE"
          >
            Save
            {loading && (
              <>
                <FacebookCircularProgress />
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditBlogs;
