import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import data from "layouts/blogs/data";
import Card from "@mui/material/Card";
import DataTable from "examples/Tables/DataTable";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertDialog from "components/ConfirmationDialog/ConfirmationDialog";
import { toast, ToastContainer } from "react-toastify";
import BlogsTable from "components/blogsTable";

const columns = [
  {
    Header: "Title",
    accessor: "title",
    width: "25%",
    align: "left",
    sortType: "alphanumeric",
  },
  {
    Header: "Categories",
    accessor: "category",
    width: "15%",
    align: "left",
    sortType: "alphanumeric",
  },
  {
    Header: "Cover Image",
    accessor: "coverImage",
    width: "15%",
    align: "left",
    sortType: "alphanumeric",
  },
  // { Header: "Description", accessor: "description", width: "10%", align: "center",sortType: 'alphanumeric'  },
  // { Header: "Content", accessor: "content", width: "15%", align: "center",sortType: 'alphanumeric'  },
  {
    Header: "Status",
    accessor: "status",
    width: "10%",
    align: "center",
    sortType: "alphanumeric",
  },
  {
    Header: "Comments",
    accessor: "comments",
    align: "center",
    sortType: "alphanumeric",
  },
  {
    Header: "SEO",
    accessor: "seoMetaDescription",
    align: "center",
    sortType: "alphanumeric",
  },
  {
    Header: "Likes",
    accessor: "likes",
    align: "center",
    sortType: "alphanumeric",
  },
  {
    Header: "Views",
    accessor: "views",
    align: "center",
    sortType: "alphanumeric",
  },
  {
    Header: "Action",
    accessor: "action",
    width: "25%",
    align: "left",
    sortType: "alphanumeric",
  },
];

function Blogs() {
  const [menu, setMenu] = useState(null);
  const [response, setResponse] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedblog, setSelectedBlog] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/blog/getAllBlog`
        // "http://localhost:8080/api/admin/blog/getAllBlog"
      );
      setResponse(response.data);
    } catch (error) {
      console.error("Error:", error);
      const notify = () => toast("SomeThing Went Wrong");
      notify();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    // console.log(id);
    try {
      const deleteblog = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/blog/deleteBlog/${id}`
        // `http://localhost:8080/api/admin/blog/deleteBlog/${id}`
      );
      const updatedData = response.filter((item) => item._id !== id);
      setResponse(updatedData);
      const notify = () => toast("Blog Deleted Successfully");
      notify();
      setOpen(false);
    } catch (error) {
      console.error("Error:", error);
      const notify = () => toast("SomeThing Went Wrong");
      notify();
    }
  };

  const [isEditing, setIsEditing] = useState(false);
  const [newBlog, setNewBlog] = useState(false);
  const handleEdit = (item) => {
    setResponse(item);
    setIsEditing(true);
    setNewBlog(true);
    // console.log("Editing item:", item);
  };

  const resetForm = () => {
    setFormData({
      name: "",
      description: "",
      coverImage: "",
      heading: "",
      url: "",
      offers: [],
    });
    setIsEditing(false);
  };

  // const handleDelete=async(id)=>{
  //   try {
  //     const deleteblog = await axios.delete(`http://localhost:8080/api/admin/blog/deleteBlog/${selectedblog}`);
  //     if(deleteblog.status == 200){
  //       await fetchData();
  //       const notify = () => toast("Blog Deleted Successfully");
  //       notify();
  //       setOpen(false);
  //     }
  //   } catch (error) {
  //     const notify = () => toast("SomeThing Went Wrong");
  //     notify();
  //   }
  // }

  const rows = response?.map((item, index) => ({
    title: (
      <MDBox display="flex" alignItems="center" lineHeight={1} key={index}>
        <MDTypography variant="body1" fontWeight="medium" ml={1} lineHeight={1}>
          {item.title}
        </MDTypography>
      </MDBox>
    ),
    category: (
      <MDTypography variant="body2" color="textSecondary">
        {item.category}
      </MDTypography>
    ),
    coverImage: (
      <MDTypography variant="body2" color="textSecondary">
        {item.coverImage}
      </MDTypography>
    ),
    // decription: (
    //     <MDTypography variant="body2" color="textSecondary" align="center">
    //       <div

    //       dangerouslySetInnerHTML={{ __html:  item?.content ? item.description.slice(0, 20) : '' }}
    //       style={{
    //         padding: '20px',
    //       }}
    //     />
    //     </MDTypography>
    //   ),
    // content: (
    //   <MDTypography variant="body2" color="textSecondary" align="center">
    //     <div
    //     dangerouslySetInnerHTML={{ __html: item?.content ? item.content.slice(0, 20) : ''}}
    //     style={{
    //       padding: '20px',
    //     }}
    //   />
    //   </MDTypography>
    // ),
    status: (
      <MDTypography variant="body2" color="textSecondary" align="center">
        {item.status}
      </MDTypography>
    ),
    comments: (
      <MDTypography variant="body2" color="textSecondary" align="center">
        {item.comments}
      </MDTypography>
    ),
    seoMetaDescription: (
      <MDTypography variant="body2" color="textSecondary" align="center">
        {item.seoMetaDescription}
      </MDTypography>
    ),
    likes: (
      <MDTypography variant="body2" color="textSecondary" align="center">
        {item.likes}
      </MDTypography>
    ),
    views: (
      <MDTypography variant="body2" color="textSecondary" align="center">
        {item.views}
      </MDTypography>
    ),

    action: (
      <MDBox width="3rem" textAlign="center" display="flex" gap={5}>
        <EditIcon color="primary" sx={{ cursor: "pointer", mr: 2 }} />
        <DeleteIcon
          color="error"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            // console.log(item._id);
            handleClickOpen();
            setSelectedBlog(item?._id);
          }}
        />
      </MDBox>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        <AlertDialog
          open={open}
          setOpen={setOpen}
          agree={"Delete"}
          disagree={"Cancel"}
          data={"Are you sure You want to delete That Blog"}
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      }
      <MDBox pt={6} pb={3}>
        <ToastContainer />
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  All Posts
                </MDTypography>
              </MDBox>
              <MDBox className= "overflow-hidden">
                <BlogsTable
                  data={response}
                  onEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Blogs;

// <DashboardLayout>
//   <DashboardNavbar />
//   <MDBox mt={8}>
//     <Card>
//       <MDBox>
//         <DataTable
//           table={{ columns, rows }}
//           showTotalEntries={true}
//           isSorted={true}
//           noEndBorder
//           entriesPerPage={true}
//           canSearch={true}
//         />
//       </MDBox>
//     </Card>
//   </MDBox>
//   {/* <Footer /> */}
// </DashboardLayout>
