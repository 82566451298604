import React from "react";

const QuestionsTable = ({ data, handleDelete }) => {
  // console.log("questionTable", data);
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow-md sm:rounded-lg">
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                  >
                    Question
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                  >
                    comment
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item) => (
                  <tr key={item._id} className="bg-white border-b">
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {item.name}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {item.email}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {item.question}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {item.comment}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="flex flex-row gap-10">
                        <p
                          className="cursor-pointer"
                          onClick={() => handleDelete(item._id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 48 48"
                          >
                            <g
                              fill="none"
                              stroke-linejoin="round"
                              stroke-width="4"
                            >
                              <path
                                fill="#DD0002"
                                stroke="#000"
                                d="M9 10V44H39V10H9Z"
                              />
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                d="M20 20V33"
                              />
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                d="M28 20V33"
                              />
                              <path
                                stroke="#000"
                                stroke-linecap="round"
                                d="M4 10H44"
                              />
                              <path
                                fill="#2f88ff"
                                stroke="#000"
                                d="M16 10L19.289 4H28.7771L32 10H16Z"
                              />
                            </g>
                          </svg>
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionsTable;
