import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import OrdersTable from "../../components/ordersTable.js";
import axios from "axios";
import FacebookCircularProgress from "../../common/FacebookCircularProgress";
import { toast, ToastContainer } from "react-toastify";

const ManageOrders = () => {
  const [formData, setFormData] = useState({
    status: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [idToEdit, setIdToEdit] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/contactUs/getAllCategories`
        );
        // console.log("Fetched categories:", response.data); // Add this line for debugging
        setCategories(response.data);
        setLoadingCategories(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoadingCategories(false);
      }
    };
    fetchCategories();
  }, []);

  // console.log(categories);
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/contactUs/getAllOrders`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    fetchOrders();
  }, []);

  const handleChange = (e) => {
    // console.log(e.target.name, e.target.value); // Add this line for debugging
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDelete = async (id) => {
    try {
      const notify = () => toast("Order Deleted");
      notify();
    } catch (error) {
      const notify = () => toast("Something Went Wrong");
      notify();
      console.error("Error deleting order:", error);
    }
  };

  const handleEditClick = async (id) => {
    setIsEditing(true);
    setIdToEdit(id);
    // You might want to load the specific order data here to edit
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      // console.log("formData before submit:", formData); // For debugging
  
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/contactUs/updateOrder/${idToEdit}`,
        formData,
        config
      );
  
      // Refetch orders after updating
      const updatedOrders = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/contactUs/getAllOrders`
      );
      setData(updatedOrders.data);
  
      setIsEditing(false);
      resetForm();
      setLoading(false);
      toast(response.data.message);
    } catch (error) {
      console.error("Error updating order:", error);
      toast("Something went wrong");
      setLoading(false);
    }
  };
  

  const resetForm = () => {
    setFormData({
      status: "",
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {isEditing && (
        <div className="relative z-10">
          <div className="absolute top-20 left-52 w-full">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[60rem]">
              <div className="flex justify-center items-center">
                <h2 className="text-xl font-bold mb-4">
                  {isEditing ? "Edit Order" : "Add Review"}
                </h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="space-y-2">
                  <label
                    htmlFor="status"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Status
                  </label>
                  <select
                    id="status"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
                  >
                    <option value="">Select a status</option>
                    {loadingCategories ? (
                      <option disabled>Loading categories...</option>
                    ) : categories.length > 0 ? (
                      categories.map((category, index) => (
                        <option key={index} value={category.name}>
                          {category.name}
                        </option>
                      ))
                    ) : (
                      <option disabled>No categories available</option>
                    )}
                  </select>
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => {
                      resetForm();
                      setIsEditing(false);
                    }}
                    className="bg-gray-500 h-10 px-3 justify-center text-center rounded-sm text-white text-sm font-semibold mx-5"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex bg-blue-500 h-10 px-3 justify-center items-center text-center rounded-sm text-white text-sm font-semibold mx-5"
                  >
                    {isEditing ? "Update Status" : "Add Review"}
                    {loading && <FacebookCircularProgress />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Orders List
                </MDTypography>
              </MDBox>
              <MDBox pt={3} className="overflow-hidden">
                <OrdersTable
                  data={data}
                  handleDelete={handleDelete}
                  handleEditClick={handleEditClick}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default ManageOrders;
