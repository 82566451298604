import React from "react";
import moment from "moment";
const ReviewsTable = ({ data, handleDelete, handleEdit }) => {
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow-md sm:rounded-lg">
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                  >
                    Review
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                  >
                    Stars
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                  >
                    Created At
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item._id} className="bg-white border-b">
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {item.name}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {item.review}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {item.stars}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {moment(item.createdAt).format("DD MMM YYYY")}
                    </td>
                    <td className="whitespace-nowrap py-4">
                      <div className="flex flex-row  mx-4 gap-5">
                        <p
                          className="cursor-pointer"
                          onClick={() => handleEdit(item)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="2em"
                            viewBox="0 0 48 48"
                          >
                            <g
                              fill="none"
                              stroke="#000"
                              stroke-linejoin="round"
                              stroke-width="4"
                            >
                              <path stroke-linecap="round" d="M7 42H43" />
                              <path
                                fill="#338CED"
                                d="M11 26.7199V34H18.3172L39 13.3081L31.6951 6L11 26.7199Z"
                              />
                            </g>
                          </svg>
                        </p>
                        <p
                          className="cursor-pointer flex justify-center items-center"
                          onClick={() => handleDelete(item._id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 48 48"
                          >
                            <g
                              fill="none"
                              stroke-linejoin="round"
                              stroke-width="4"
                            >
                              <path
                                fill="#DD0002"
                                stroke="#000"
                                d="M9 10V44H39V10H9Z"
                              />
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                d="M20 20V33"
                              />
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                d="M28 20V33"
                              />
                              <path
                                stroke="#000"
                                stroke-linecap="round"
                                d="M4 10H44"
                              />
                              <path
                                fill="#2f88ff"
                                stroke="#000"
                                d="M16 10L19.289 4H28.7771L32 10H16Z"
                              />
                            </g>
                          </svg>
                        </p>
                      </div>
                    </td>

                    {/* <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <button
                        onClick={() => handleEdit(item)}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(item._id)}
                        className="ml-4 text-red-600 hover:text-red-900"
                      >
                        Delete
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsTable;
