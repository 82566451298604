import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";
import data from "layouts/blogs//categories/data";
import AddCategories from "./addcategories";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertDialog from "components/ConfirmationDialog/ConfirmationDialog";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";
import CategoriesTable from "components/categoriesTable";
const columns = [
  {
    Header: "Category Name",
    accessor: "category",
    width: "25%",
    align: "left",
    sortType: "alphanumeric",
  },
  {
    Header: "Action",
    accessor: "action",
    width: "25%",
    align: "left",
    sortType: "alphanumeric",
  },
];

function Categories() {
  // const { columns, rows } = data();
  const [menu, setMenu] = useState(null);
  const [view, setView] = useState("categories");
  // console.log(view);
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/category/getAllCategories`
        // "http://localhost:8080/api/admin/category/getAllCategories"
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleDelete = async (id) => {
    // console.log("id", id);
    try {
      const deleteblog = await axios.delete(
        // `http://localhost:8080/api/admin/category/deleteCategory/${id}`
        `${process.env.REACT_APP_BASE_URL}/category/deleteCategory/${id}`
      );
      if (deleteblog.status == 200) {
        await fetchCategories();
        const notify = () => toast("Category Deleted Successfully");
        notify();
        setOpen(false);
      }
    } catch (error) {
      const notify = () => toast("SomeThing Went Wrong");
      notify();
    }
  };
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const handleEdit = (item) => {
    // console.log/("Editing item:", item);
    setIsEditing(true);
    setView("addCategory");
    setFormData((prevFormData) => ({ ...prevFormData, ...item }));
  };

  useEffect(() => {
    // console.log("FormData updated:", formData);
  }, [formData]);
  const rows = categories?.map((item, index) => ({
    category: (
      <MDTypography variant="body2" color="textSecondary">
        {item.category}
      </MDTypography>
    ),
    action: (
      <MDBox width="3rem" textAlign="center" display="flex" gap={5}>
        <EditIcon color="primary" sx={{ cursor: "pointer", mr: 2 }} />
        <DeleteIcon
          color="error"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            // console.log(item._id);
            handleClickOpen();
            setSelectedCategory(item?._id);
          }}
        />
      </MDBox>
    ),
  }));

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      {
        <AlertDialog
          open={open}
          setOpen={setOpen}
          agree={"Delete"}
          disagree={"Cancel"}
          data={"Are you sure You want to delete That Category"}
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      }
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mt={4} mb={4} display="flex" gap={10}>
          {/* <MDButton
            variant={view === "categories" ? "contained" : "gradient"}
            color="info"
            type="submit"
            onClick={() => {
              setView("categories");
              setIsEditing(false);
              setFormData("");
            }}
            sx={{ opacity: view === "categories" ? 1 : 0.7 }}
          >
            Categories
          </MDButton> */}
          <MDButton
            variant={view === "addCategory" ? "contained" : "gradient"}
            color="info"
            type="submit"
            onClick={() => setView("addCategory")}
            sx={{ opacity: view === "addCategory" ? 1 : 0.7 }}
          >
            New Category
          </MDButton>
        </MDBox>

        {view === "categories" ? (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Categories
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    {/* <DataTable
                      table={{ columns, rows }}
                      showTotalEntries={false}
                      isSorted={false}
                      noEndBorder
                      entriesPerPage={false}
                    /> */}
                    <CategoriesTable
                      categories={categories}
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        ) : (
          // <Card>
          //   <MDBox>
          //     <DataTable
          //       table={{ columns, rows }}
          //       showTotalEntries={false}
          //       isSorted={false}
          //       noEndBorder
          //       entriesPerPage={false}
          //     />
          //   </MDBox>
          // </Card>
          <>
            <Card>
              <AddCategories
                data={formData}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                itemToEdit={formData._id}
                fetchCategories={fetchCategories}
                setView = {setView}
              />
            </Card>
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Categories;
