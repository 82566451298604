import React, { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;
  const location = useLocation();
  const [expandedKey, setExpandedKey] = useState(
    location.pathname.replace("/", "")
  );

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setExpandedKey(location.pathname.replace("/", ""));
  }, [location]);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    window.addEventListener("resize", handleMiniSidenav);

    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const handleParentClick = (key) => {
    setExpandedKey(expandedKey === key ? "" : key); // Toggle or set only the clicked tab
  };

  const renderRoutes = routes.map(
    ({
      type,
      name,
      icon,
      title,
      noCollapse,
      key,
      href,
      route,
      children,
      parentKey,
    }) => {
      const isExpanded = expandedKey === key;

      let returnValue;

      if (type === "title" && children) {
        return (
          <React.Fragment key={key}>
            <MDBox
              onClick={() => handleParentClick(key)}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ paddingRight: "16px" }} // Add padding to ensure icons are within hover area
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === expandedKey}
                noCollapse={noCollapse}
                isExpanded={isExpanded}
              />
              {isExpanded ? (
                <ExpandLessIcon
                  sx={{ ml: "auto", color: "white !important" }} // Use ml: "auto" to push icon to the right
                />
              ) : (
                <ExpandMoreIcon
                  sx={{ ml: "auto", color: "white !important" }} // Use ml: "auto" to push icon to the right
                />
              )}
            </MDBox>
            {isExpanded && (
              <MDBox marginLeft="20px" marginRight="20px">
                <List ml={20}>
                  {children.map((child) => {
                    if (child.type === "collapse") {
                      return child.href ? (
                        <Link
                          href={child.href}
                          key={child.key}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          <SidenavCollapse
                            name={child.name}
                            icon={child.icon}
                            active={child.key === expandedKey}
                            noCollapse={child.noCollapse}
                          />
                        </Link>
                      ) : (
                        <NavLink
                          key={child.key}
                          to={child.route}
                          onClick={() => setExpandedKey(key)} // Keep parent expanded
                        >
                          <SidenavCollapse
                            name={child.name}
                            icon={child.icon}
                            active={child.key === expandedKey}
                          />
                        </NavLink>
                      );
                    }
                    return null;
                  })}
                </List>
              </MDBox>
            )}
          </React.Fragment>
        );
      }

      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === expandedKey}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink
            key={key}
            to={route}
            onClick={() => setExpandedKey(key)} // Keep this tab expanded
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === expandedKey}
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={1} pb={1} px={2} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && (
            <MDBox
              component="img"
              height="100px"
              src={brand}
              alt="Brand"
              width="5rem"
            />
          )}
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
