import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ServicesTable from "../../components/servicesTable.js";
import axios from "axios";
import FacebookCircularProgress from "../../common/FacebookCircularProgress";
import { toast, ToastContainer } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import uploadFile from "../../components/handleUploadFile.js";
// import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
const manageServices = () => {
  const [services, setServices] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleServices = () => {
    setServices(!services);
    setNewServices(false);
  };

  const [newServices, setNewServices] = useState(false);

  const handleNewServices = () => {
    setNewServices(!newServices);
    setServices(false);
    setIsEditing(false);
  };

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    coverImage: null,
    heading: "",
    url: "",
    offers: [],
    // icon: null,
  });

  const handleFileUpload = async (selectedFile) => {
    try {
      const fileUrl = await uploadFile(selectedFile);
      // console.log("File successfully uploaded at:", fileUrl);
      setFormData((prevData) => ({
        ...prevData,
        coverImage: fileUrl,
      }));
    } catch (error) {
      console.error("File upload failed:", error.message);
      // Optionally handle the error in the UI, e.g., show a notification
    }
  };

  const handleChange = async (e, index = null) => {
    const { name, value, files } = e.target;

    if (name === "coverImage" && files.length > 0) {
      await handleFileUpload(files[0]);
    } else if (name.startsWith("offer")) {
      const offers = [...formData.offers];
      offers[index] = value;
      setFormData({
        ...formData,
        offers,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleEdit = (item) => {
    setFormData(item);
    setIsEditing(true);
    setNewServices(true);
    // console.log("Editing item:", item);
  };
  // const handleIconChange = (icon) => {
  //   setFormData({
  //     ...formData,
  //     icon,
  //   });
  // };

  const addOfferField = () => {
    setFormData({
      ...formData,
      offers: [...formData.offers, ""],
    });
  };

  const resetForm = () => {
    setFormData({
      name: "",
      description: "",
      coverImage: "",
      heading: "",
      url: "",
      offers: [],
    });
    setIsEditing(false);
  };

  const removeOfferField = (index) => {
    const offers = [...formData.offers];
    offers.splice(index, 1);
    setFormData({
      ...formData,
      offers,
    });
  };
  const [data, setData] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true);

    const token = localStorage.getItem("token");
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const url = isEditing
        ? `${process.env.REACT_APP_BASE_URL}/service/updateService/${formData._id}`
        : `${process.env.REACT_APP_BASE_URL}/service/createService`;

      const method = isEditing ? "put" : "post";
      const response = await axios[method](url, formData, config);

      // Reset form data
      setFormData({
        name: "",
        description: "",
        coverImage: null,
        heading: "",
        offers: [""],
        url: "",
      });

      setNewServices((prev) => !prev);

      // Fetch and update services
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/service/getAllServices`
      );
      setData(data);

      const notify = () => toast(response.data.message);
      notify();
    } catch (error) {
      console.error("Error:", error);
      const notify = () => toast("Something went wrong");
      notify();
    } finally {
      setLoading(false);
    }
  };

  const handleHeadingChange = (heading) => {
    setFormData((prevState) => ({
      ...prevState,
      description: heading,
    }));
  };
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
    }
  };

  const fetchData = async () => {
    try {
      const getAllServices = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/service/getAllServices`
        // "http://localhost:8080/api/admin/service/getAllServices"
      );
      // console.log("Response data:", getAllServices);
      setData(getAllServices.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    // console.log(id);
    try {
      const deleteResponse = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/service/deleteService/${id}`
        // `http://localhost:8080/api/admin/service/deleteService/${id}`
      );
      const notify = () => toast(deleteResponse.data.message);
      notify();
      const updatedData = data.filter((item) => item._id !== id);
      setData(updatedData);
    } catch (error) {
      const notify = () => toast("Something went wrong");
      notify();
      console.error("Error:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {newServices && (
        <div className="relative z-20">
          <div className="absolute top-[3rem] left-1/2 transform -translate-x-1/2 w-full max-w-[60rem]">
            <div className="bg-white p-6 rounded-lg shadow-lg mx-auto">
              <div className="flex justify-center items-center">
                <h2 className="text-xl font-bold mb-4">
                  {isEditing ? "" : "Add Service"}
                </h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div className="space-y-2">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
                    />
                  </div>

                  <div className="space-y-2">
                    <label
                      htmlFor="url"
                      className="block text-sm font-medium text-gray-700"
                    >
                      URL
                    </label>
                    <input
                      type="text"
                      id="url"
                      name="url"
                      value={formData.url}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold text-sm">
                    Description
                  </label>
                  <Editor
                    apiKey="9s5ou8sknrgi85liuccpawfylv0y58eudqkdj43cci3yqrj0"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    name="description"
                    value={formData.description}
                    // initialValue="<p>This is the initial content of the editor.</p>"
                    onEditorChange={handleHeadingChange}
                    init={{
                      top: "15vh",
                      height: "40vh",
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      outputFormat: "html",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>

                {isEditing && (
                  <div className="space-y-2">
                    <label className="block text-gray-700 font-bold text-sm">
                      Heading
                    </label>
                    <textarea
                      name="heading"
                      onChange={handleChange}
                      value={formData.heading}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                      required
                    ></textarea>
                  </div>

                  // <div className="mb-4">
                  //   <label className="block text-gray-700 font-bold text-sm">
                  //     Heading
                  //   </label>
                  //   <textarea
                  //     name="heading"
                  //     onChange={handleChange}
                  //     value={formData.heading}
                  //     className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                  //     required
                  //   ></textarea>
                  // </div>
                )}

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="space-y-2 mb-4">
                    <label
                      htmlFor="coverImage"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Cover Image
                    </label>
                    <input
                      type="file"
                      id="coverImage"
                      name="coverImage"
                      accept="image/*"
                      onChange={(e) => handleChange(e)}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
                    />
                  </div>

                  {/* <div className="mb-4">
                    <label className="block text-gray-700 font-bold text-sm">
                      Icon
                    </label>
                    <style>
                      {`
      select {
        font-family: FontAwesome, Arial;
      }
    `}
                    </style>
                    <select
                      name="icon"
                      value={formData.icon}
                      onChange={(e) => handleIconChange(e.target.value)}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
                    >
                      <option value="">Select an icon</option>
                      {iconOptions.map((icon) => (
                        <option key={icon.value} value={icon.value}>
                          {icon.unicode} {icon.label}
                        </option>
                      ))}
                    </select>
                    <div className="mt-2">
                      {formData.icon && (
                        <i
                          className={`fa ${formData.icon} fa-2x`}
                          aria-hidden="true"
                        ></i>
                      )}
                    </div>
                  </div> */}
                </div>

                {formData.offers.map((offer, index) => (
                  <div key={index} className="mb-4 flex items-center">
                    <input
                      type="text"
                      name={`offer${index}`}
                      value={offer}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
                      onChange={(e) => handleChange(e, index)}
                      placeholder={`Offer ${index + 1}`}
                    />
                    <button
                      type="button"
                      onClick={() => removeOfferField(index)}
                      className="ml-2 text-red-500"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addOfferField}
                  className="bg-blue-500 h-10 px-3 justify-center items-center text-center rounded-sm text-white text-sm font-semibold mx-5"
                >
                  Add Offer
                </button>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => {
                      setNewServices(false);
                      resetForm();
                      setLoading(false);
                    }}
                    className="bg-gray-500 h-10 px-3 justify-center text-center rounded-sm text-white text-sm font-semibold mx-5"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex bg-blue-500 h-10 px-3 justify-center items-center text-center rounded-sm text-white text-sm font-semibold mx-5"
                  >
                    {isEditing ? "Update Service" : "Add Service"}

                    {loading && <FacebookCircularProgress />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <div className="gap-5">
        {/* <button
          onClick={handleServices}
          className="h-10 px-3 justify-center text-center rounded-sm text-white text-sm font-semibold  mx-5"
          style={{ background: services ? "#2F88EC" : "#589FEF" }}
        >
          Services{" "}
        </button> */}
        <button
          onClick={handleNewServices}
          className=" h-10 px-3 justify-center text-center rounded-sm text-white text-sm font-semibold mx-5"
          style={{ background: newServices ? "#2F88EC" : "#589FEF" }}
        >
          Add New Service
        </button>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Services
                </MDTypography>
              </MDBox>
              <MDBox pt={3} className="z-10 overflow-hidden">
                <ServicesTable
                  data={data}
                  onEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              </MDBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Projects Table
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns: pColumns, rows: pRows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid> */}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default manageServices;
