import React, { useState, useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import FacebookCircularProgress from "../common/FacebookCircularProgress";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import uploadFile from "./handleUploadFile";
import AWS from "aws-sdk";
const UpdateServicesContent = ({formData ,handleChange}) => {
  return (
    <div className="container mx-auto px-4 py-6">
      <form className="space-y-6">


        {/* Services SECTION */}

  
        <div className="space-y-2">
          <label
            htmlFor="url"
            className="block text-sm font-medium text-gray-700"
          >
            Services Heading
          </label>
          <input
            type="text"
            id="servicesHeading"
            name="servicesHeading"
            value={formData.servicesHeading}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          />
        </div>

        <div className="space-y-2">
          <label
            htmlFor="url"
            className="block text-sm font-medium text-gray-700"
          >
            Services Content
          </label>
          <textarea
            id="servicesContent"
            name="servicesContent"
            value={formData.servicesContent}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          ></textarea>
        </div>

        {/* SERVICES SECTION END */}
      </form>
    </div>
  );
};

export default UpdateServicesContent;
    