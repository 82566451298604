import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import PagesTable from "../../components/pagesTable.js";
import axios from "axios";
import FacebookCircularProgress from "../../common/FacebookCircularProgress";
import { toast, ToastContainer } from "react-toastify";
const manageServices = () => {
  const [services, setServices] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleServices = () => {
    setServices(!services);
    setNewServices(false);
  };

  // console.log(services);
  const [newServices, setNewServices] = useState(false);

  const handleNewServices = () => {
    setNewServices(!newServices);
    setServices(false);
  };

  // console.log(newServices);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Ensure the `name` attribute is correct and matches `formData` keys

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getAllPages = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/page/getAllPages`
          // "http://localhost:8080/api/admin/page/getAllPages"
        );
        // console.log("Response data:", getAllPages);
        setData(getAllPages.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const deleteResponse = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/page/deletePage/${id}`
        // `http://localhost:8080/api/admin/homePageText/deleteHomepageText/${id}`
      );
      const notify = () => toast(deleteResponse.data.message);
      notify();
      const updatedData = data.filter((item) => item._id !== id);
      setData(updatedData);
    } catch (error) {
      const notify = () => toast("Something Went Wrong");
      notify();
      console.error("Error:", error);
    }
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* <div className="gap-5">
        <button
          onClick={handleServices}
          className="h-10 px-3 justify-center text-center rounded-sm text-white text-sm font-semibold  mx-5"
          style={{ background: services ? "#2F88EC" : "#589FEF" }}
        >
          Services{" "}
        </button>
        <button
          onClick={handleNewServices}
          className=" h-10 px-3 justify-center text-center rounded-sm text-white text-sm font-semibold mx-5"
          style={{ background: newServices ? "#2F88EC" : "#589FEF" }}
        >
          Add New Service
        </button>
      </div> */}

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Pages List
                </MDTypography>
              </MDBox>
              <MDBox pt={3} className="overflow-hidden">
                {/* <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    /> */}
                <PagesTable data={data} handleDelete={handleDelete} />
              </MDBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Projects Table
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns: pColumns, rows: pRows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid> */}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default manageServices;
