import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

function AddCategories({
  data,
  setIsEditing,
  isEditing,
  itemToEdit,
  fetchCategories,
  setView,
}) {
  // console.log("isEditing", isEditing);
  // console.log("itemToEdit", itemToEdit);
  const [formData, setFormData] = useState({
    category: "",
    // description: "",
  });

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setFormData(data);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);
    try {
      let response;
      if (isEditing) {
        response = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/category/updateCategory/${itemToEdit}`,
          // `http://localhost:8080/api/admin/category/updateCategory/${itemToEdit}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/category/createCategory`,
          // "http://localhost:8080/api/admin/category/createCategory",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }
      setFormData({
        category: "",
      });
      const notify = () => toast(response.data.message);
      notify();
      fetchCategories();
      // console.log(response.data);
      setIsEditing(false); // Reset the editing state if needed
    } catch (error) {
      const notify = () => toast(response.data.message);
      notify();
      console.error("Error:", error);
    }
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-2">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Category Name
          </label>
          <input
            type="text"
            id="category"
            name="category"
            value={formData.category}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          />
        </div>

        <div className="pt-5 flex justify-end">
          <button
            type="button"
            onClick={() => {
              setView("categories");
            }}
            className="bg-gray-500 h-10 px-3 justify-center text-center rounded-sm text-white text-sm font-semibold mx-5"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#3790EE] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:#3790EE"
          >
            {isEditing ? "Update Category" : "Add Category"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddCategories;
