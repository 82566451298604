import React from "react";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
const PagesTable = ({ data , handleDelete }) => {
const navigate = useNavigate();
  const handleEditClick = (id) => {
    localStorage.setItem("idTOEditPage", id);
    navigate(`/pages/newpage`); // Adjust the path as necessary
  };

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-left text-sm font-light">
              <thead className="border-b font-medium dark:border-neutral-500">
                <tr>
                  <th scope="col"  className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Name
                  </th>
                  <th scope="col"  className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    URL
                  </th>
                  <th scope="col"  className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    TITLE
                  </th>
                  <th scope="col"  className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    STATUS
                  </th>
                  {/* <th scope="col" className="px-6 py-4">Status</th> */}
                  <th scope="col"  className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    SEO ROBOT ACCESS
                  </th>
                  <th scope="col"  className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    CREATED AT
                  </th>
                  <th scope="col"  className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr
                    key={index}
                    className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600"
                  >
                    <td className="whitespace-nowrap px-6 py-4">{item.name}</td>
                    <td className="whitespace-nowrap px-6 py-4">{item.url}</td>
                    <td className="whitespace-nowrap px-6 py-4">
                      {item.title}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      {item.status}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      {item.seoRobotAccess}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
  {moment(item.createdAt).format('DD MMM YYYY')}
</td>
       


                    <td className="whitespace-nowrap py-4">
                      <div className="flex flex-row  mx-4 gap-5">
                        <p
                          className="cursor-pointer"
                          onClick={() => handleEditClick(item._id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="2em"
                            viewBox="0 0 48 48"
                          >
                            <g
                              fill="none"
                              stroke="#000"
                              stroke-linejoin="round"
                              stroke-width="4"
                            >
                              <path stroke-linecap="round" d="M7 42H43" />
                              <path
                                fill="#338CED"
                                d="M11 26.7199V34H18.3172L39 13.3081L31.6951 6L11 26.7199Z"
                              />
                            </g>
                          </svg>
                        </p>
                        <p
                          className="cursor-pointer flex justify-center items-center"
                          onClick={() => handleDelete(item._id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 48 48"
                          >
                            <g
                              fill="none"
                              stroke-linejoin="round"
                              stroke-width="4"
                            >
                              <path
                                fill="#DD0002"
                                stroke="#000"
                                d="M9 10V44H39V10H9Z"
                              />
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                d="M20 20V33"
                              />
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                d="M28 20V33"
                              />
                              <path
                                stroke="#000"
                                stroke-linecap="round"
                                d="M4 10H44"
                              />
                              <path
                                fill="#2f88ff"
                                stroke="#000"
                                d="M16 10L19.289 4H28.7771L32 10H16Z"
                              />
                            </g>
                          </svg>
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagesTable;
