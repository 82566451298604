import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import QuestionsTable from "../components/questionsTable";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

const NewPostComponent = () => {
  const [data, setData] = useState([]);

  const handleDelete = async (id) => {
    try {
      const deleteResponse = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/ask/deleteQuestion/${id}`
      );
      toast(deleteResponse.data.message); // Directly use toast here

      const updatedData = data.filter((item) => item._id !== id);
      setData(updatedData);
    } catch (error) {
      toast("Something Went Wrong");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/ask/getquestions`
        );

        setData(response.data.data); // Access data from response.data.data
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  // console.log("qesdata", data);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Questions
                </MDTypography>
              </MDBox>
              <MDBox pt={3} className="z-10 overflow-hidden">
                <QuestionsTable data={data} handleDelete={handleDelete} /> 
                {/* Pass correct data prop */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
};

export default NewPostComponent;
