import React, { useState, useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import FacebookCircularProgress from "../common/FacebookCircularProgress";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import uploadFile from "./handleUploadFile";
import AWS from "aws-sdk";
const UpdateNavItems = ({ formData, handleChange }) => {
  return (
    <div className="container mx-auto px-4 py-6">
      <form className="space-y-6">
        {/*UPDATE NAV ITEMS SECTION */}

        <div className="space-y-2">
          <label
            htmlFor="url"
            className="block text-sm font-medium text-gray-700"
          >
            Item One Name
          </label>
          <input
            type="text"
            name="navItemOne"
            id="navItemOne"
            value={formData.navItemOne}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          />
        </div>
        <div className="space-y-2">
          <label
            htmlFor="url"
            className="block text-sm font-medium text-gray-700"
          >
              Item Two Name
          </label>
          <input
            type="text"
            name="navItemTwo"
            id="navItemTwo"
            value={formData.navItemTwo}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          />
        </div>
        <div className="space-y-2">
          <label
            htmlFor="url"
            className="block text-sm font-medium text-gray-700"
          >
            Item Three Name
          </label>
          <input
            type="text"
            name="navItemThree"
            id="navItemThree"
            value={formData.navItemThree}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          />
        </div>
        <div className="space-y-2">
          <label
            htmlFor="url"
            className="block text-sm font-medium text-gray-700"
          >
            Item Four Name
          </label>
          <input
            type="text"
            name="navItemFour"
            id="navItemFour"
            value={formData.navItemFour}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          />
        </div>
        <div className="space-y-2">
          <label
            htmlFor="url"
            className="block text-sm font-medium text-gray-700"
          >
            Button Text
          </label>
          <input
            type="text"
            name="navItemButtonText"
            id="navItemButtonText"
            value={formData.navItemButtonText}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
          />
        </div>

        {/* CONTACT US SECTION END */}
      </form>
    </div>
  );
};

export default UpdateNavItems;
