import React, { useState, useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import FacebookCircularProgress from "../common/FacebookCircularProgress";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import uploadFile from "./handleUploadFile";
import AWS from "aws-sdk";
const UpdateAboutUs = ({ formData, handleChange }) => {
 
  return (
    <div className="container mx-auto px-4 py-6">
      <form className="space-y-6">


               {/* About Us Section WHO ARE WE? */}

        <div className="space-y-2">
          <div className="space-y-2">
            <label
              htmlFor="coverImage"
              className="block text-sm font-medium text-gray-700"
            >
              About Us Image
            </label>
            <input
              type="file"
              id="aboutUsImage"
              name="aboutUsImage"
              accept="image/*"
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
            />
          </div>
          <div className="space-y-2">
            <label
              htmlFor="url"
              className="block text-sm font-medium text-gray-700"
            >
              About Us Title
            </label>
            <input
              type="text"
              id="aboutUsTitle"
              name="aboutUsTitle"
              value={formData.aboutUsTitle}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
            />
          </div>
          <div className="space-y-2">
            <label
              htmlFor="url"
              className="block text-sm font-medium text-gray-700"
            >
              About Us Content
            </label>
            <textarea
              id="aboutUsContent"
              name="aboutUsContent"
              value={formData.aboutUsContent}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#3790EE] focus:border-[#3790EE] sm:text-sm"
            ></textarea>
          </div>
        </div>
    
        {/*WHO ARE WE?  END  */}

      </form>
    </div>
  );
};

export default UpdateAboutUs;
    